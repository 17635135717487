<!--
 * @Description: 商城订单详情
 * @Author: 琢磨先生
 * @Date: 2023-05-29 16:06:40
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-05-31 15:43:21
-->
 
<template>
  <el-drawer
    title="订单详情"
    v-model="visible"
    direction="rtl"
    size="1000px"
    :destroy-on-close="true"
    :show-close="true"
    @closed="onClosed"
  >
    <div v-loading="loading">
      <el-descriptions title="" :column="2">
        <el-descriptions-item label="单号">{{model.order_no}}</el-descriptions-item>
        <el-descriptions-item label="商品">{{model.goods_title}}</el-descriptions-item>
        <el-descriptions-item label="规格">{{model.sku?.spec_values}}</el-descriptions-item>
        <el-descriptions-item label="数量">x{{ model.number }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{ model.status_text }}</el-descriptions-item>
        <el-descriptions-item label="快递" :width="300">
          <span>{{model.waybill_no}}</span>
          <el-popover placement="bottom" :width="600" v-if="model.is_deliver">
            <template #reference>
              <el-link :underline="false" type="primary">物流信息</el-link>
            </template>
            <div style="height:400px;">
              <el-scrollbar>
                <div class="waybill" v-for="item in model.waybill_list" :key="item.id">
                  <div class="no">
                    <b>{{item.company_name}}、{{item.waybill_no}}</b>
                  </div>
                  <el-alert type="info" :closable="false" title>
                    <el-timeline v-if="item.track_list.length>0">
                      <el-timeline-item
                        v-for="(activity, index) in item.track_list"
                        :key="index"
                        :timestamp="activity.ftime"
                      >{{ activity.context }}</el-timeline-item>
                    </el-timeline>
                    <div v-else>
                      <span>暂无物流信息</span>
                    </div>
                  </el-alert>
                </div>
              </el-scrollbar>
            </div>
          </el-popover>
        </el-descriptions-item>
        <el-descriptions-item label="商品金额">￥{{ model.decimal_amount }}</el-descriptions-item>
        <el-descriptions-item label="收件地址">{{ model.consignee }} {{ model.consignee_mobile_phone }} {{ model.consignee_address }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ model.create_at }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </el-drawer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      visible: false,
      model:{},
    };
  },
  props: ["id"],
  emits: ["close"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.visible = true;
          this.loadDtl();
        }
      },
      immediate: true,
    },
  },

  created() {},
  methods: {
    onClosed() {
      this.$emit("close");
    },
    /**
     * 加载详情
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("seller/v1/mallOrder/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style   scoped>
</style>